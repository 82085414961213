<script lang="ts" setup>
import type { Promotion, OfferPreset } from "@/types";

const { t } = useT();
const router = useRouter();
const { data: appInitData } = useAppInitData();
const { depositInfoData } = useFunzcityDepositStreak();

defineProps<{
	allPackages: OfferPreset[];
	allPromotions: Promotion[];
}>();

const checkDepositStreakActive = (value: number) =>
	!!appInitData.value?.depositStreak?.isActive && value >= (depositInfoData.value?.minDepositValue ?? 0);

const handleGoToPromotions = () => {
	router.push("/promotions/");
};
</script>

<template>
	<div class="promotions">
		<div class="header" @click="handleGoToPromotions">
			<AText variant="tulsa" :modifiers="['bold', 'uppercase']" class="text-tlalnepantla title">
				<NuxtImg
					src="/nuxt-img/promotions/best-deals-prizebox.webp"
					class="icon"
					width="64"
					height="64"
					alt="best-deals-prizebox"
					loading="lazy"
				/>
				<span>{{ t("DEALS OF THE DAY") }}</span>
			</AText>
			<AText variant="taipei" :modifiers="['bold']" class="text-tlalnepantla text-сirebon counter">
				{{ allPromotions.length }}
			</AText>
		</div>

		<div class="deals">
			<OHomeBannerPromotionItem
				v-for="(offer, i) in allPackages"
				:key="i"
				:offer="offer"
				:withDepositStreak="checkDepositStreakActive(offer?.money)"
				:eventDecor="true"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.promotions {
	min-width: 439px;
	max-width: 100%;
	max-height: 376px;
	border-radius: 20px;
	padding: gutter(2) 0 0 gutter(2);
	background: var(--celaya);
	display: flex;
	flex-direction: column;
	align-items: center;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			justify-content: space-between;
			min-width: 100%;
			border-radius: 12px;
			padding: gutter(2);
			background: var(--celaya);
		}
	}

	@include media-breakpoint-down(md) {
		justify-content: space-between;
		min-width: 100%;
		border-radius: 12px;
		padding: gutter(2);
		background: var(--celaya);
	}
}

.header {
	.icon {
		width: 32px;
		height: 32px;
		object-fit: cover;
		display: none;
	}
	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			width: 100%;
			display: flex;
			justify-content: space-between;

			.icon {
				display: inline;
				position: relative;
				padding-right: 4px;
			}

			.title {
				span {
					text-decoration: underline;
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.icon {
			display: inline;
			position: relative;
			top: 4px;
			padding-right: 4px;
		}

		.title {
			span {
				text-decoration: underline;
			}
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.title {
	text-shadow: 0 0 6px var(--cangzhou);

	@include media-breakpoint-down(md) {
		font-size: 24px;
	}
}

.counter {
	display: none;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			display: block;
		}
	}

	@include media-breakpoint-down(md) {
		display: block;
	}
}

.deals {
	margin-top: gutter(3.5);
	padding: 0 gutter(2) gutter(2) 0;
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: gutter(1.5);

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: var(--сirebon);
	}

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}
</style>
